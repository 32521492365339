import React from "react"
import { graphql, Link } from "gatsby"
import { connect } from "react-redux"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"

const CareersPage = ({ data }) => {
  const { allMdx } = data

  const jobs = allMdx.nodes.map((item, key) => {
    const { title, path, location } = item.frontmatter

    return (
      <Link key={key} className="job-listing" to={path}>
        <div>
          <h1>{title}</h1>
          <p>{location}</p>
        </div>
      </Link>
    )
  })

  return (
    <Page
      zeroTop
      seoPath="careers"
      seoTitle="Rallista - Careers"
      seoDescription="Help us build the future of Rallista."
    >
      <FancyHeader
        title={"Careers"}
        subTitle={"Help us Build the Future of Rallista"}
      />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            {jobs}
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

CareersPage.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  // actions: bindActionCreators(EmailerActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CareersPage)

export const pageQuery = graphql`
  query CareersPageQuery {
    allMdx(filter: { frontmatter: { path: { regex: "/careers/" } } }) {
      nodes {
        frontmatter {
          path
          seoTitle
          subTitle
          title
          keywords
          type
          location
        }
      }
    }
  }
`
